// import the html parser

const preventWidows = (string: string) => {
  // a little safeguarding: simply return if the
  // data passed isn't suitable
  if (!string || string.length < 0) {
    return string;
  }

  // trim whitespace and then split into an array
  // of words
  const words = string.trim().split(" ");

  // count the number of words
  const totalWords = words.length;

  // if there are fewer than four words then don't do anything -
  // otherwise we will end up with an orphan on the first
  // line instead (!)
  if (totalWords < 4) {
    return string;
  }

  // wrap the last two words in a nobr
  const formattedLastWords = `<nobr>${words[totalWords - 2]} ${
    words[totalWords - 1]
  }</nobr>`;

  // remove the last two words from the array
  words.splice(-2, 2);

  // return the remaining words, re-joined with a space, followed
  // by the last two words (wrapped in a nobr), as a JSX object
  return `${words.join(" ")} ${formattedLastWords}`;
};

export default preventWidows;
