import DiscoverMoreButton from "@/components/DiscoverMoreButton";
import preventWidows from "@/lib/preventWidows";
import { Transition } from "@headlessui/react";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";

const Hero = ({
  heading,
  subHeading = "",
  showScrollButton = false,
}: {
  heading: string;
  subHeading?: string;
  showScrollButton?: boolean;
}) => {
  const [scrollButtonVisible, setScrollButtonVisible] = useState(false);

  const { ref, inView, entry } = useInView({ triggerOnce: true });

  // Set scrollButtonVisible to true after 1.5 seconds using useEffect
  useEffect(() => {
    if (inView) {
      setTimeout(() => {
        setScrollButtonVisible(true);
      }, 1500);
    }
  }, [inView]);

  const handleScrollButtonClick = () => {
    // Scroll the viewport the full height of component
    window.scrollBy({
      top: entry?.boundingClientRect.height,
      left: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className={clsx("Hero", "relative pt-header")} ref={ref}>
      <div className={clsx("Hero__container", "container")}>
        <div className={clsx("relative")}>
          <div className={clsx("")}>
            {!!heading && (
              <h1
                className={clsx(
                  "Hero__heading",
                  "heading-2xl sm:heading-3xl max-w-7xl",
                  "transition duration-1000 ease-out",
                  {
                    "translate-y-2 opacity-0": !inView,
                    "translate-y-0 opacity-100": inView,
                  },
                )}
                dangerouslySetInnerHTML={{ __html: preventWidows(heading) }}
              ></h1>
            )}
            {!!subHeading && (
              <div
                className={clsx(
                  "Hero__subHeading",
                  "heading-md max-w-3xl pt-8 transition delay-100 duration-1000 ease-out xl:pt-16",
                  {
                    "translate-y-2 opacity-0": !inView,
                    "translate-y-0 opacity-100": inView,
                  },
                )}
                dangerouslySetInnerHTML={{ __html: preventWidows(subHeading) }}
              ></div>
            )}
          </div>
          {!!showScrollButton && (
            <Transition
              show={inView && scrollButtonVisible}
              enter={clsx("transition ease-out duration-500 ease-out")}
              enterFrom={clsx("opacity-0 translate-y-4")}
              enterTo={clsx("opacity-100 translate-y-0")}
              leave={clsx("transition ease-out duration-500 ease-out")}
              leaveFrom={clsx("opacity-100 translate-y-0")}
              leaveTo={clsx("opacity-0 -translate-y-4")}
            >
              <div
                className={clsx("absolute right-0 bottom-0 hidden lg:block")}
              >
                <DiscoverMoreButton onClick={handleScrollButtonClick} />
              </div>
            </Transition>
          )}
        </div>
      </div>
    </div>
  );
};

export default Hero;
