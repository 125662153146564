import clsx from "clsx";
import { ArrowDown } from "react-feather";

// Create typescript interface for DiscoverMoreButton props
interface DiscoverMoreButtonProps {
  onClick: () => void;
}

const DiscoverMoreButton = ({
  onClick = () => null,
}: DiscoverMoreButtonProps) => {
  return (
    <button
      className={clsx("flex h-32 w-32 items-center justify-center")}
      onClick={onClick}
    >
      <div
        className={clsx(
          "absolute inset-0 flex items-center justify-center text-red"
        )}
      >
        <ArrowDown strokeWidth={1.5} />
      </div>
      <svg
        viewBox="0 0 124 124"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={clsx("h-full w-full animate-spin-slow text-red")}
      >
        <path
          d="M25.75 106.568C24.4459 105.545 23.7081 104.386 23.5364 103.09C23.3648 101.795 23.797 100.487 24.8331 99.166C25.8693 97.8455 27.0371 97.1143 28.3367 96.9724C29.6362 96.8306 30.938 97.271 32.2421 98.2938L34.9128 100.389L28.4207 108.663L25.75 106.568ZM31.404 99.1554C30.4594 98.4146 29.5041 98.1176 28.5381 98.2646C27.5572 98.4133 26.6733 98.9892 25.8864 99.9921C25.0995 100.995 24.7538 101.986 24.8494 102.966C24.9302 103.947 25.4428 104.808 26.3874 105.549L28.1553 106.936L33.172 100.542L31.404 99.1554Z"
          fill="#EC000C"
        />
        <path
          d="M26.2978 92.4743L27.1481 93.4242L19.3128 100.44L18.4625 99.4902L26.2978 92.4743Z"
          fill="#EC000C"
        />
        <path
          d="M12.5312 91.5414C11.5314 89.9489 11.4408 88.406 12.2595 86.9129L13.5997 87.3693C13.1755 88.0122 12.9606 88.599 12.9549 89.1295C12.9347 89.6567 13.1279 90.2442 13.5346 90.892C13.9639 91.5758 14.4468 92.0252 14.9831 92.2402C15.5049 92.4518 15.9861 92.4191 16.4269 92.1422C16.7147 91.9613 16.905 91.735 16.9979 91.4633C17.0908 91.1917 17.099 90.8352 17.0226 90.3938C16.9316 89.9492 16.7423 89.378 16.4549 88.6804C15.9128 87.3773 15.6871 86.3586 15.7778 85.624C15.8629 84.8804 16.2563 84.2882 16.9579 83.8473C17.4616 83.5307 17.9972 83.3949 18.5647 83.4397C19.1176 83.4812 19.6658 83.695 20.2093 84.0812C20.7438 84.4731 21.2257 85.0109 21.655 85.6947C22.2199 86.5944 22.5275 87.4739 22.5777 88.3332C22.6224 89.1834 22.4568 89.9587 22.0812 90.6591L20.741 90.2027C21.1191 89.6262 21.3098 89.0108 21.3129 88.3564C21.3013 87.6987 21.0724 87.0144 20.6262 86.3036C20.1856 85.6018 19.7094 85.1231 19.1977 84.8675C18.6771 84.6176 18.1828 84.6396 17.7151 84.9335C17.3193 85.1822 17.1175 85.56 17.1096 86.0669C17.1017 86.5737 17.3048 87.3369 17.7191 88.3564C18.2578 89.674 18.4892 90.7018 18.4131 91.4398C18.337 92.1777 17.9526 92.7643 17.26 93.1995C16.7833 93.4991 16.2578 93.6411 15.6836 93.6256C15.1094 93.61 14.5472 93.4238 13.997 93.0669C13.4378 92.7157 12.9492 92.2072 12.5312 91.5414Z"
          fill="#EC000C"
        />
        <path
          d="M13.301 73.2684C13.9362 73.2743 14.5292 73.3974 15.08 73.6376C15.6209 73.8815 16.0923 74.2298 16.4944 74.6825C16.8928 75.1253 17.2045 75.6562 17.4296 76.2752C17.7709 77.2136 17.8622 78.1019 17.7035 78.94C17.5311 79.7717 17.1347 80.5154 16.5141 81.1713C15.8898 81.8172 15.0537 82.3312 14.0057 82.7132C12.9576 83.0951 11.9872 83.2397 11.0943 83.1468C10.1977 83.0439 9.4214 82.7276 8.76529 82.1978C8.09556 81.6617 7.59369 80.9344 7.25967 80.0159C7.02731 79.3769 6.91019 78.7751 6.90832 78.2104C6.89647 77.6494 7.00168 77.1305 7.22395 76.6537C7.43261 76.1706 7.74834 75.7333 8.17116 75.3417L9.15535 76.3229C8.66629 76.7499 8.35328 77.2258 8.21632 77.7506C8.06574 78.2691 8.11026 78.8578 8.34988 79.5168C8.59313 80.1857 8.96704 80.7165 9.4716 81.1093C9.96618 81.5057 10.5606 81.7413 11.2547 81.8162C11.9453 81.8811 12.7098 81.7608 13.5482 81.4552C14.3766 81.1532 15.0413 80.7583 15.5421 80.2705C16.033 79.7863 16.3474 79.2364 16.4852 78.6208C16.6131 78.0088 16.5536 77.3633 16.3067 76.6844C16.0671 76.0255 15.6936 75.5115 15.1864 75.1423C14.6691 74.7768 14.0362 74.6061 13.2875 74.6302L13.301 73.2684Z"
          fill="#EC000C"
        />
        <path
          d="M4.66972 67.709C4.54663 66.7287 4.66076 65.8469 5.0121 65.0636C5.3529 64.2817 5.90458 63.6444 6.66716 63.1517C7.41787 62.6498 8.34129 62.3296 9.4374 62.1909C10.5335 62.0523 11.5125 62.1319 12.3743 62.4298C13.2243 62.7185 13.9163 63.1985 14.4503 63.8698C14.9737 64.5425 15.297 65.369 15.4201 66.3493C15.5432 67.3296 15.4343 68.2107 15.0935 68.9927C14.7422 69.776 14.1911 70.4185 13.4404 70.9204C12.6779 71.4131 11.7485 71.7287 10.6524 71.8674C9.55628 72.006 8.58323 71.931 7.73326 71.6423C6.87142 71.3444 6.17877 70.8591 5.65533 70.1864C5.12134 69.5151 4.79281 68.6893 4.66972 67.709ZM5.85542 67.5591C5.99174 68.6448 6.47377 69.4565 7.30149 69.9943C8.11734 70.5229 9.17873 70.7045 10.4856 70.5392C11.7925 70.3739 12.7811 69.933 13.4513 69.2164C14.1097 68.4907 14.3707 67.5849 14.2344 66.4992C14.0981 65.4135 13.622 64.6064 12.8061 64.0778C11.9784 63.54 10.9111 63.3538 9.60417 63.5191C8.29726 63.6844 7.31463 64.1299 6.65627 64.8557C5.98605 65.5722 5.7191 66.4734 5.85542 67.5591Z"
          fill="#EC000C"
        />
        <path
          d="M15.4832 55.605L15.3488 57.0489L4.50513 60.066L4.63216 58.7014L12.1161 56.7029L14.2569 56.2281L12.2561 55.3715L5.25257 52.0374L5.3796 50.6729L15.4832 55.605Z"
          fill="#EC000C"
        />
        <path
          d="M17.6452 42.6034L18.7878 42.954L16.5485 50.2344L6.49368 47.1499L8.66272 40.0979L9.80532 40.4484L8.01106 46.282L11.256 47.2774L12.6521 42.7385L13.7947 43.089L12.3986 47.6279L15.7807 48.6654L17.6452 42.6034Z"
          fill="#EC000C"
        />
        <path
          d="M17.9219 35.7746L16.7062 37.9795L20.5307 40.0859L19.9152 41.2023L10.7028 36.1283L12.6264 32.6397C13.1444 31.7 13.8019 31.0797 14.5987 30.7788C15.3954 30.4778 16.2079 30.5554 17.0361 31.0115C17.6875 31.3703 18.1365 31.8541 18.3831 32.4629C18.6297 33.0718 18.6624 33.7386 18.481 34.4635L23.8623 34.0436L23.1314 35.3693L17.9219 35.7746ZM15.6593 37.4029L16.9597 35.0445C17.6778 33.7421 17.4878 32.7885 16.3898 32.1837C15.2917 31.5789 14.3837 31.9278 13.6655 33.2302L12.3652 35.5885L15.6593 37.4029Z"
          fill="#EC000C"
        />
        <path
          d="M37.3107 21.5529L36.3784 22.3258L32.1586 17.234L30.4997 15.0075L30.4875 15.0177L33.8145 24.4516L32.9803 25.1432L24.3293 20.1235L24.317 20.1337L26.1967 22.177L30.4165 27.2689L29.4841 28.0419L22.7731 19.944L24.282 18.693L30.1382 22.1383L32.4294 23.6542L32.4539 23.6339L31.4119 21.1029L29.0908 14.706L30.5997 13.455L37.3107 21.5529Z"
          fill="#EC000C"
        />
        <path
          d="M38.3136 9.49764C39.2126 9.06283 40.0843 8.88905 40.9286 8.9763C41.7779 9.04936 42.5551 9.36384 43.26 9.91974C43.9745 10.471 44.5743 11.2489 45.0594 12.2534C45.5445 13.2579 45.7809 14.2115 45.7686 15.1143C45.7658 16.0125 45.5315 16.8221 45.0656 17.5431C44.6047 18.2498 43.9248 18.8206 43.0258 19.2554C42.1268 19.6902 41.2527 19.8711 40.4034 19.7981C39.559 19.7108 38.7796 19.3916 38.065 18.8403C37.3601 18.2844 36.7651 17.5042 36.28 16.4997C35.7949 15.4952 35.5538 14.5439 35.5565 13.6457C35.5688 12.7429 35.8054 11.9381 36.2664 11.2313C36.7322 10.5103 37.4146 9.93245 38.3136 9.49764ZM38.8333 10.5739C38.183 10.8884 37.6927 11.3144 37.3623 11.8518C37.0274 12.3796 36.8694 12.993 36.8883 13.6919C36.9027 14.3812 37.1016 15.1229 37.485 15.9169C37.8685 16.7109 38.3279 17.3324 38.8633 17.7815C39.3941 18.2211 39.9725 18.4783 40.5985 18.5531C41.2199 18.6184 41.8557 18.4938 42.5061 18.1792C43.166 17.86 43.6586 17.4388 43.984 16.9156C44.3144 16.3782 44.4724 15.7649 44.458 15.0755C44.439 14.3767 44.2378 13.6302 43.8544 12.8362C43.471 12.0422 43.0138 11.4254 42.4831 10.9859C41.9477 10.5368 41.3693 10.2796 40.7479 10.2143C40.1314 10.1349 39.4932 10.2547 38.8333 10.5739Z"
          fill="#EC000C"
        />
        <path
          d="M51.851 11.7093L49.4012 12.2904L50.4052 16.5396L49.1648 16.8338L46.7464 6.59836L50.6227 5.67899C51.6667 5.43137 52.5675 5.5016 53.325 5.88967C54.0826 6.27774 54.5701 6.93186 54.7875 7.85202C54.9585 8.57574 54.9011 9.23353 54.6152 9.82541C54.3293 10.4173 53.8569 10.8896 53.198 11.2424L57.119 14.9473L55.646 15.2966L51.851 11.7093ZM49.1264 11.1272L51.7468 10.5057C53.1939 10.1625 53.7733 9.38091 53.4851 8.16092C53.1968 6.94094 52.3291 6.50256 50.882 6.84579L48.2616 7.46728L49.1264 11.1272Z"
          fill="#EC000C"
        />
        <path
          d="M66.2392 13.7535L66.2707 14.9482L58.6565 15.1559L58.3795 4.64223L65.7548 4.44108L65.7862 5.63581L59.6853 5.80221L59.7747 9.19525L64.5216 9.06578L64.5531 10.2605L59.8062 10.39L59.8993 13.9264L66.2392 13.7535Z"
          fill="#EC000C"
        />
        <path
          d="M78.8606 10.4518C79.4189 10.6241 79.8223 10.9377 80.0708 11.3924C80.3192 11.8471 80.3602 12.3434 80.1937 12.8813C80.0273 13.4192 79.713 13.8058 79.2509 14.0412C78.7888 14.2765 78.2786 14.308 77.7203 14.1357C77.1722 13.9665 76.7688 13.6529 76.5102 13.195C76.2648 12.7302 76.2238 12.2339 76.3872 11.7061C76.5505 11.1784 76.8632 10.7969 77.3253 10.5615C77.8007 10.3191 78.3125 10.2825 78.8606 10.4518Z"
          fill="#EC000C"
        />
        <path
          d="M96.7415 16.2349C98.0783 17.2144 98.8537 18.3489 99.0676 19.6383C99.2815 20.9277 98.8923 22.2492 97.8999 23.603C96.9075 24.9567 95.7642 25.7257 94.4699 25.91C93.1756 26.0943 91.8601 25.6967 90.5233 24.7171L87.7854 22.711L94.0035 14.2288L96.7415 16.2349ZM91.3327 23.8286C92.3011 24.5381 93.2656 24.8036 94.2263 24.6252C95.2018 24.4444 96.0664 23.84 96.8202 22.8118C97.5739 21.7837 97.8869 20.7816 97.7593 19.8057C97.6466 18.8275 97.106 17.9837 96.1377 17.2742L94.3253 15.9462L89.5203 22.5006L91.3327 23.8286Z"
          fill="#EC000C"
        />
        <path
          d="M96.656 30.339L95.7751 29.4175L103.377 22.1493L104.258 23.0708L96.656 30.339Z"
          fill="#EC000C"
        />
        <path
          d="M110.448 30.8211C111.499 32.3801 111.64 33.9191 110.871 35.4382L109.516 35.0259C109.919 34.3694 110.115 33.7759 110.103 33.2455C110.106 32.718 109.894 32.1372 109.466 31.503C109.015 30.8336 108.517 30.4003 107.974 30.203C107.446 30.0085 106.966 30.0569 106.534 30.3481C106.253 30.5383 106.07 30.7707 105.986 31.0452C105.902 31.3198 105.905 31.6764 105.996 32.1149C106.102 32.5564 106.309 33.1211 106.62 33.8089C107.204 35.0935 107.463 36.1043 107.396 36.8414C107.336 37.5874 106.962 38.1921 106.275 38.6557C105.782 38.9886 105.251 39.1419 104.682 39.1156C104.128 39.0922 103.573 38.8964 103.018 38.5282C102.471 38.1541 101.971 37.6324 101.52 36.963C100.926 36.0822 100.59 35.2133 100.511 34.3561C100.439 33.5078 100.579 32.7275 100.931 32.0153L102.286 32.4276C101.927 33.0161 101.756 33.6374 101.775 34.2916C101.808 34.9486 102.059 35.6249 102.528 36.3207C102.992 37.0077 103.483 37.4706 104.003 37.7093C104.532 37.9421 105.025 37.9039 105.483 37.5949C105.87 37.3333 106.059 36.9492 106.051 36.4423C106.042 35.9355 105.814 35.1794 105.367 34.174C104.785 32.8747 104.52 31.8551 104.572 31.1151C104.624 30.3751 104.989 29.7763 105.667 29.3186C106.134 29.0036 106.654 28.8445 107.229 28.8412C107.803 28.838 108.371 29.0057 108.933 29.3444C109.503 29.6771 110.008 30.1694 110.448 30.8211Z"
          fill="#EC000C"
        />
        <path
          d="M110.275 49.1089C109.324 49.1361 108.472 48.8948 107.718 48.3849C106.974 47.8711 106.418 47.1557 106.05 46.2388C105.682 45.3218 105.564 44.442 105.695 43.5992C105.836 42.7524 106.206 41.9912 106.805 41.3154C107.418 40.6454 108.236 40.1044 109.262 39.6923C110.277 39.2842 111.237 39.11 112.142 39.1697C113.052 39.2393 113.845 39.533 114.522 40.0508C115.209 40.5647 115.732 41.2703 116.092 42.1675C116.468 43.1042 116.59 43.9653 116.459 44.7509C116.332 45.5463 115.957 46.2523 115.334 46.869L114.318 45.9205C114.803 45.4738 115.105 44.9859 115.225 44.4569C115.345 43.9279 115.276 43.3429 115.019 42.702C114.75 42.0316 114.357 41.5083 113.84 41.1323C113.333 40.7523 112.728 40.5431 112.026 40.5048C111.323 40.4664 110.568 40.6097 109.76 40.9347C108.538 41.426 107.693 42.1032 107.227 42.9663C106.774 43.8352 106.752 44.7775 107.159 45.793C107.424 46.4536 107.821 46.9581 108.35 47.3065C108.879 47.6549 109.51 47.8019 110.244 47.7474L110.275 49.1089Z"
          fill="#EC000C"
        />
        <path
          d="M119.083 54.3828C119.239 55.3585 119.153 56.2436 118.828 57.038C118.513 57.8307 117.982 58.4857 117.236 59.003C116.502 59.5292 115.59 59.8795 114.499 60.0539C113.408 60.2283 112.427 60.1807 111.556 59.9112C110.697 59.6505 109.989 59.1933 109.434 58.5398C108.889 57.8847 108.538 57.0692 108.383 56.0935C108.228 55.1177 108.308 54.2335 108.623 53.4408C108.949 52.6464 109.478 51.9862 110.212 51.46C110.958 50.9427 111.877 50.5968 112.968 50.4224C114.059 50.248 115.034 50.2911 115.893 50.5519C116.764 50.8214 117.472 51.2838 118.017 51.939C118.573 52.5924 118.928 53.4071 119.083 54.3828ZM117.903 54.5715C117.732 53.4908 117.223 52.6953 116.378 52.1848C115.546 51.6832 114.479 51.5364 113.178 51.7444C111.877 51.9523 110.904 52.4254 110.257 53.1634C109.623 53.9104 109.392 54.8241 109.563 55.9048C109.735 56.9855 110.238 57.7766 111.07 58.2782C111.915 58.7886 112.988 58.9399 114.289 58.7319C115.59 58.524 116.557 58.0465 117.191 57.2996C117.838 56.5615 118.075 55.6521 117.903 54.5715Z"
          fill="#EC000C"
        />
        <path
          d="M108.672 66.834L108.759 65.3865L119.498 62.0162L119.416 63.3842L112.001 65.6266L109.877 66.1711L111.905 66.9618L119.014 70.065L118.932 71.4329L108.672 66.834Z"
          fill="#EC000C"
        />
        <path
          d="M106.936 79.8991L105.782 79.5862L107.782 72.2363L117.933 74.9901L115.995 82.1092L114.842 81.7963L116.445 75.9072L113.169 75.0185L111.922 79.6006L110.768 79.2877L112.015 74.7056L108.601 73.7794L106.936 79.8991Z"
          fill="#EC000C"
        />
        <path
          d="M106.883 86.7328L108.026 84.4894L104.134 82.5092L104.713 81.3733L114.087 86.143L112.278 89.6927C111.791 90.6487 111.154 91.2902 110.368 91.6171C109.581 91.944 108.767 91.893 107.924 91.4642C107.261 91.127 106.797 90.6581 106.53 90.0577C106.264 89.4573 106.209 88.7919 106.367 88.0615L101.002 88.6573L101.689 87.3084L106.883 86.7328ZM109.091 85.0314L107.869 87.4309C107.193 88.7562 107.414 89.703 108.532 90.2715C109.649 90.84 110.545 90.4617 111.22 89.1365L112.443 86.7369L109.091 85.0314Z"
          fill="#EC000C"
        />
        <path
          d="M87.9685 101.581L88.8751 100.778L93.2594 105.729L94.9902 107.899L95.0022 107.889L91.3681 98.5692L92.1792 97.8507L100.99 102.585L101.002 102.574L99.0565 100.593L94.6723 95.6423L95.5788 94.8392L102.551 102.713L101.084 104.013L95.1182 100.761L92.7787 99.3208L92.7548 99.3419L93.8791 101.837L96.4083 108.155L94.9411 109.454L87.9685 101.581Z"
          fill="#EC000C"
        />
        <path
          d="M87.3605 113.662C86.4856 114.121 85.6201 114.323 84.7639 114.268C83.9127 114.223 83.1232 113.929 82.3955 113.388C81.6633 112.86 81.0408 112.107 80.528 111.128C80.0152 110.149 79.7478 109.204 79.7256 108.292C79.699 107.394 79.9066 106.577 80.3487 105.842C80.7956 105.115 81.4565 104.522 82.3314 104.063C83.2063 103.604 84.0694 103.398 84.9206 103.443C85.7768 103.497 86.571 103.789 87.3032 104.316C88.0309 104.858 88.6511 105.618 89.1639 106.597C89.6767 107.576 89.9464 108.514 89.9731 109.412C89.9952 110.324 89.7828 111.143 89.3359 111.869C88.8939 112.605 88.2354 113.203 87.3605 113.662ZM86.8058 112.603C87.7748 112.095 88.3665 111.358 88.5808 110.394C88.7907 109.444 88.59 108.386 87.9786 107.219C87.3672 106.052 86.609 105.28 85.7039 104.903C84.7943 104.541 83.855 104.614 82.8861 105.122C81.9171 105.63 81.3277 106.36 81.1178 107.31C80.9035 108.274 81.102 109.339 81.7133 110.506C82.3247 111.673 83.0852 112.438 83.9948 112.8C84.8999 113.177 85.8369 113.111 86.8058 112.603Z"
          fill="#EC000C"
        />
        <path
          d="M73.7574 111.894L76.1869 111.233L75.0444 107.019L76.2745 106.685L79.0267 116.835L75.1825 117.881C74.1471 118.163 73.2445 118.122 72.4747 117.759C71.7048 117.396 71.1961 116.758 70.9487 115.845C70.7541 115.128 70.79 114.468 71.0564 113.867C71.3227 113.266 71.7794 112.779 72.4264 112.405L68.3863 108.83L69.8471 108.433L73.7574 111.894ZM76.4997 112.387L73.901 113.094C72.4659 113.484 71.9123 114.284 72.2404 115.494C72.5684 116.704 73.45 117.114 74.8851 116.723L77.4838 116.016L76.4997 112.387Z"
          fill="#EC000C"
        />
        <path
          d="M59.3101 110.322L59.2396 109.129L66.8429 108.672L67.4638 119.171L60.099 119.613L60.0284 118.42L66.1207 118.054L65.9203 114.666L61.1801 114.951L61.1096 113.758L65.8498 113.473L65.641 109.941L59.3101 110.322Z"
          fill="#EC000C"
        />
        <path
          d="M46.8048 114.035C46.2411 113.881 45.8277 113.581 45.5645 113.134C45.3013 112.688 45.2441 112.193 45.3929 111.65C45.5417 111.107 45.8432 110.711 46.2973 110.46C46.7514 110.21 47.2603 110.162 47.824 110.316C48.3774 110.467 48.7908 110.767 49.0643 111.216C49.3247 111.673 49.3819 112.167 49.2359 112.7C49.0899 113.233 48.7898 113.625 48.3356 113.875C47.8684 114.133 47.3581 114.186 46.8048 114.035Z"
          fill="#EC000C"
        />
      </svg>
    </button>
  );
};

export default DiscoverMoreButton;
